import React, { Component } from "react";
import API_keuangan from "../services/keuangan/Api_keuangan";
import key_keuangan from "../services/keuangan/key_keuangan";
import imageCompression from "browser-image-compression";
import convertRupiah from "../molecules/convertRupiah";
export default class bemergency extends Component {
  state = {
    // form upload bukti bayar
    formpost: {
      bukti: "",
      status: "",
      email: "",
      kodevoucher: "",
      nominal_voucher: "",
      total_before: "",
      total_after: "",
    },

    // info camaba
    status: "",
    infoBiaya: {
      Periode: "",
      Pendaftaran: "",
      DaftarUlang: "",
      total: "",
      disclaimer: "",
      Pendaftaran: "",
      total: "",
    },

    // voucher
    voucher: [],
    voucherInput: "",
    kodeVoucher: "",
    detailVoucher: [],

    // popup
    isSuccess: false,
    isDanger: false,
    isPopup: false,
    isLoading: false,

    // popup alert
    title: "",
    subtitle: "",
  };

  fetchInfoBiaya = async () => {
    const res = await API_keuangan.get(
      "cari/biaya/pendaftaran/view" +
        key_keuangan.baseURL +
        "&uo=" +
        this.props.match.params.conf +
        "&status=" +
        this.state.status
    );
    if (res) {
      this.setState({
        infoBiaya: res.data,
      });
    }
  };

  fetchVoucher = async (jenis) => {
    this.setState({ isLoading: true });
    const resVoucher = await API_keuangan.get(
      "dropdown/voucher" + key_keuangan.baseURL + "&jenis=" + jenis
    );
    if (resVoucher) {
      this.setState({ voucher: resVoucher.data, isLoading: false });
      if (sessionStorage.getItem("voucher") != null) {
        this.logicVoucher(sessionStorage.getItem("voucher"));
      }
    }
  };

  fetchDataCamaba = async () => {
    this.setState({ isLoading: true, title: "Loading Data" });
    const resBiodata = await API_keuangan.get(
      "pembayaran/tampil/cari/camaba/view" +
        key_keuangan.baseURL +
        "&uo=" +
        this.props.match.params.conf
    );
    if (resBiodata) {
      this.setState(
        {
          nomor_pendaftaran: resBiodata.data[0].nopendaftaran,
          nama_pendaftar: resBiodata.data[0].nama,
          ttl: resBiodata.data[0].ttl,
          email: resBiodata.data[0].email,
          status: resBiodata.data[0].status,
          no_hp: resBiodata.data[0].nohp,
          alamat: resBiodata.data[0].alamat,
        },
        async () => {
          await this.fetchInfoBiaya();
          await this.fetchVoucher(resBiodata.data[0].status);
          this.setState({ isLoading: false, title: "" });
        }
      );
    }
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.fetchInfoBiaya();
        this.fetchVoucher(e.target.value);
      }
    );
  };

  logicVoucher = (kode) => {
    const res = this.state.voucher.filter((x) => x.kode_voucher == kode);
    this.setState({ detailVoucher: res[0] });
  };

  claimVoucher = async (kode, type) => {
    this.setState({ isLoading: true, title: "Claiming" });
    let option = "";
    if (type == "manual") {
      option = "&type=manual";
    } else {
      option = "";
    }
    const res = await API_keuangan.get(
      "klaim/voucher" +
        key_keuangan.baseURL +
        "&nodaftar=" +
        this.props.match.params.conf +
        "&kodevoucher=" +
        kode +
        option
    );
    if (type == "auto") {
      if (res.data) {
        this.setState({ isLoading: false, title: "" });
        sessionStorage.setItem("voucher", kode);
        this.setState({ kodeVoucher: kode });
        this.logicVoucher(kode);
      } else {
        this.setState({
          isLoading: false,
          isDanger: true,
          title: "Invalid",
          subtitle: "kode voucher invalid/tidak dapat dipakai",
        });
      }
    }
    if (type == "manual") {
      if (res.data.length != 0) {
        this.setState({ isLoading: false, title: "" });
        this.setState({
          detailVoucher: res.data,
          isPopup: false,
          kodeVoucher: "",
        });
        sessionStorage.removeItem("voucher");
      } else {
        this.setState({
          isLoading: false,
          isDanger: true,
          title: "Invalid",
          subtitle: "kode voucher invalid/tidak dapat dipakai",
        });
      }
    }
  };

  cancelVoucher = () => {
    sessionStorage.removeItem("voucher");
    this.setState({ kodeVoucher: "", detailVoucher: [] });
  };

  handleUploadPhoto = async (event) => {
    const options = {
      maxSizeMB: 0.25,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    if (/(\.jpg|\.jpeg|\.png)$/i.test(event.target.files[0].name) === false) {
      this.setState({
        isDanger: true,
        title: "Format File Salah",
        subtitle: "file harus berformat gambar .jpg, .jpeg, .png",
      });
    } else {
      document.getElementById(
        "labelUpload"
      ).innerHTML = `<span style="color: #fff; font-size: 18px" class="fa fa-spinner fa-spin"></span>&nbsp; Loading...`;
      const compressedFile = await imageCompression(
        event.target.files[0],
        options
      );
      if (compressedFile) {
        var blobToFile = new File([compressedFile], "buktifoto.jpg", {
          type: "image/jpeg",
        });
        document.getElementById("foto").src = URL.createObjectURL(blobToFile);
        document.getElementById(
          "labelUpload"
        ).innerHTML = `<span class="fas fa-check-circle" style="font-size: 18px; color: rgb(4, 245, 56);"></span> Berhasil Unggah File`;
        document.getElementById("foto").style.display = "block";
        this.setState({
          formpost: {
            ...this.state.formpost,
            bukti: blobToFile,
          },
        });
      }
    }
  };

  handleSubmit = async () => {
    if (
      parseInt(this.state.infoBiaya.total) <
      parseInt(this.state.detailVoucher.nominal_voucher)
    ) {
      this.setState({
        isDanger: true,
        title: "Harga Biaya Invalid Dari Voucher!",
        subtitle:
          "proses klaim voucher anda tidak dapat diproses karena biaya voucher lebih besar daripada biaya total pembayaran.",
      });
    } else {
      if (this.state.formpost.bukti != "") {
        this.setState({ isLoading: true, title: "Loading" });
        const newForm = {
          ...this.state.formpost,
          status: this.state.status,
          email: this.state.email,
          kodevoucher: this.state.detailVoucher.kode_voucher ?? "",
          nominal_voucher: this.state.detailVoucher.nominal_voucher ?? "",
          total_before: this.state.infoBiaya.total,
          total_after:
            parseInt(this.state.infoBiaya.total) -
            parseInt(this.state.detailVoucher.nominal_voucher),
        };
        const formData = new FormData();
        for (const key in newForm) {
          formData.append(key, newForm[key]);
        }
        const resSubmit = await API_keuangan.post(
          "konfirmasi/pembayaran" +
            key_keuangan.baseURL +
            "&nodaftar=" +
            this.props.match.params.conf,
          formData,
          {
            "Content-type": "multipart/form-data",
          }
        );
        if (resSubmit.data.status_code == 200) {
          this.setState(
            {
              isLoading: false,
              isSuccess: true,
              title: "Berhasil !",
              subtitle: "upload bukti pembayaran berhasil.",
            },
            () => {
              setTimeout(() => {
                sessionStorage.removeItem("voucher");
                window.location.replace(
                  "/biodata/camaba/" + this.props.match.params.conf
                );
              }, 2000);
            }
          );
          const dataSend = {
            nobtk: resSubmit.data.nobtk,
            bukti: resSubmit.data.bukti,
            status: resSubmit.data.status,
          };
          await API_keuangan.post(
            "mailer/cc/pembayaran" + key_keuangan.baseURL,
            dataSend
          );
        } else {
          this.setState({
            isDanger: true,
            isLoading: false,
            title: "Gagal !",
            subtitle: "upload bukti pembayaran gagal.",
          });
        }
      } else {
        this.setState({
          isDanger: true,
          title: "Gambar Kosong !",
          subtitle: "upload bukti pembayaran dahulu.",
        });
      }
    }
  };

  componentDidMount() {
    if (sessionStorage.getItem("voucher") != null) {
      this.setState({ kodeVoucher: sessionStorage.getItem("voucher") });
    }
    this.fetchDataCamaba();
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <div id="preloader">
            <div className="jumper">
              <li>
                <span className="fa fa-spinner fa-spin"></span>&nbsp;{" "}
                <span>{this.state.title}</span>
              </li>
            </div>
          </div>
        ) : null}

        {this.state.isSuccess ? (
          <div className="popbox active">
            <div className="popup-box active">
              <div className="icon">
                <i className="fa fa-check" />
              </div>
              <header>{this.state.title}</header>
              <div className="pop-load">
                <div className="muter">
                  <span className="fa fa-spinner fa-spin" />
                  &nbsp;&nbsp;&nbsp;<span>{this.state.subtitle}</span>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.isDanger ? (
          <div className="popbox-warning active">
            <div className="popup-box-warning active">
              <div className="icon">
                <i className="fas fa-exclamation" />
              </div>
              <h1 style={{ fontSize: "25px" }}>{this.state.title}</h1>
              <p style={{ marginTop: "-2%", marginBottom: "1%" }}>
                {this.state.subtitle}
              </p>
              <label onClick={() => this.setState({ isDanger: false })}>
                OK
              </label>
            </div>
          </div>
        ) : null}

        <div
          className="container-confirm"
          style={{
            position: "relative",
            width: "100%",
            minHeight: "100vh",
            padding: "2rem",
            backgroundColor: "#fafafa",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <span className="big-circle"></span>
          <img src="../../asset/images/shape.png" className="square" alt="" />
          <div className="form" style={{ gridTemplateColumns: "none" }}>
            <div className="contact-info pembayaran">
              <div className="info-pendaftar" style={{ padding: "20px" }}>
                <img src="../../asset/images/asia.png" alt="" />
                <div className="desc">
                  <h1>{this.state.nama_pendaftar}</h1>
                  {this.state.status == "A" ? (
                    <h4>
                      Selamat Anda Sudah Menjadi Mahasiswa Institut Asia
                      &#127882;
                    </h4>
                  ) : (
                    <h4>No. Pendaftaran #{this.state.nomor_pendaftaran}</h4>
                  )}
                  <li>
                    <small>
                      <span className="fas fa-child"></span> Birthday -{" "}
                      {this.state.ttl}
                    </small>
                  </li>
                  <li>
                    <small>
                      <span className="fas fa-envelope"></span> Email -{" "}
                      {this.state.email}
                    </small>
                  </li>
                </div>
              </div>
            </div>
          </div>

          {this.state.status == "A" ? null : (
            <div className="form" style={{ marginTop: "20px" }}>
              <div className="contact-info pembayaran">
                <h1>Konfirmasi Pembayaran</h1>
                <div
                  className="list-desc"
                  style={{ marginTop: "-13%", marginBottom: "10px" }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      marginBottom: "-10px",
                      textAlign: "justify",
                    }}
                  >
                    Kini anda juga dapat melakukan pembayaran{" "}
                    <b>
                      daftar ulang secara online (dengan syarat sudah membayar
                      biaya daftar)
                    </b>
                    , atau bisa memilih paket pembayaran untuk{" "}
                    <b>daftar + daftar ulang</b> langsung. Silahkan pilih paket
                    pembayaran dibawah berikut.
                  </p>
                  <p style={{ fontSize: "13px", marginBottom: "5px" }}>
                    <b>Paket Pembayaran :</b>
                  </p>
                  {this.state.status == "DU" ? (
                    <div className="form-input-select">
                      <select name="status" className="form-control" disabled>
                        <option value="" selected>
                          Biaya Daftar Ulang
                        </option>
                      </select>
                    </div>
                  ) : (
                    <div className="form-input-select">
                      <select
                        name="status"
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.status}
                      >
                        <option value="" hidden>
                          Pilih
                        </option>
                        <option value="D">Biaya Pendaftaran</option>
                        <option value="DDU">
                          Biaya Pendaftaran + Daftar Ulang
                        </option>
                      </select>
                    </div>
                  )}
                </div>
                <button
                  className="btn-voucher"
                  style={{ margin: "0 0 10px 0" }}
                  onClick={() => this.setState({ isPopup: true })}
                >
                  gunakan voucher potongan
                </button>
                <p style={{ fontSize: "15px", margin: "15px 0 5px 0" }}>
                  <b>Informasi biaya yang harus dibayarkan :</b>
                </p>
                {this.state.status == "D" ? (
                  <>
                    <table style={{ fontSize: "14px" }}>
                      <tbody>
                        <tr>
                          <td>
                            <b>Item Biaya</b>
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>Harga</b>
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <td>Pendaftaran</td>
                          <td>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {convertRupiah(this.state.infoBiaya.Pendaftaran)}
                          </td>
                        </tr>
                        {this.state.detailVoucher.length != 0 ? (
                          <tr>
                            <td>
                              Voucher {this.state.detailVoucher.nama_voucher}
                            </td>
                            <td style={{ color: "red" }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              {convertRupiah(
                                this.state.detailVoucher.nominal_voucher
                              )}
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                      <tbody>
                        <tr>
                          <td style={{ paddingTop: "5px" }}>
                            <b>Total</b>
                          </td>
                          <td style={{ paddingTop: "5px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {this.state.detailVoucher.length != 0 ? (
                              <b>
                                {convertRupiah(
                                  parseInt(this.state.infoBiaya.total) -
                                    parseInt(
                                      this.state.detailVoucher.nominal_voucher
                                    )
                                )}
                              </b>
                            ) : (
                              <b>{convertRupiah(this.state.infoBiaya.total)}</b>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr style={{ marginTop: "5px" }} />
                  </>
                ) : this.state.status == "DDU" ? (
                  <>
                    <table style={{ fontSize: "14px" }}>
                      <tbody>
                        <tr>
                          <td>
                            <b>Item Biaya</b>
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>Harga</b>
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <td>Pendaftaran</td>
                          <td>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {convertRupiah(this.state.infoBiaya.Pendaftaran)}
                          </td>
                        </tr>
                        <tr>
                          <td>Daftar Ulang</td>
                          <td>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {convertRupiah(this.state.infoBiaya.DaftarUlang)}
                          </td>
                        </tr>
                        {this.state.detailVoucher.length != 0 ? (
                          <tr>
                            <td>
                              Voucher {this.state.detailVoucher.nama_voucher}
                            </td>
                            <td style={{ color: "red" }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              {convertRupiah(
                                this.state.detailVoucher.nominal_voucher
                              )}
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                      <tbody>
                        <tr>
                          <td style={{ paddingTop: "5px" }}>
                            <b>Total</b>
                          </td>
                          <td style={{ paddingTop: "5px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {this.state.detailVoucher.length != 0 ? (
                              <b>
                                {convertRupiah(
                                  parseInt(this.state.infoBiaya.total) -
                                    parseInt(
                                      this.state.detailVoucher.nominal_voucher
                                    )
                                )}
                              </b>
                            ) : (
                              <b>{convertRupiah(this.state.infoBiaya.total)}</b>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr style={{ marginTop: "5px" }} />
                    <span style={{ fontSize: "13px", fontWeight: "bold" }}>
                      Biaya u/ {this.state.infoBiaya.Periode}
                    </span>
                    <br />
                    <span
                      style={{
                        fontSize: "13px",
                        fontWeight: "bold",
                        color: "green",
                      }}
                    >
                      {this.state.infoBiaya.disclaimer}. Info Lebih lanjut
                      hubungi melalui WA center.
                    </span>
                  </>
                ) : this.state.status == "DU" ? (
                  <>
                    <table style={{ fontSize: "14px" }}>
                      <tbody>
                        <tr>
                          <td>
                            <b>Item Biaya</b>
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b>Harga</b>
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <td>Daftar Ulang</td>
                          <td>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {convertRupiah(this.state.infoBiaya.DaftarUlang)}
                          </td>
                        </tr>
                        {this.state.detailVoucher.length != 0 ? (
                          <tr>
                            <td>
                              Voucher {this.state.detailVoucher.nama_voucher}
                            </td>
                            <td style={{ color: "red" }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              {convertRupiah(
                                this.state.detailVoucher.nominal_voucher
                              )}
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                      <tbody>
                        <tr>
                          <td style={{ paddingTop: "5px" }}>
                            <b>Total</b>
                          </td>
                          <td style={{ paddingTop: "5px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {this.state.detailVoucher.length != 0 ? (
                              <b>
                                {convertRupiah(
                                  parseInt(this.state.infoBiaya.total) -
                                    parseInt(
                                      this.state.detailVoucher.nominal_voucher
                                    )
                                )}
                              </b>
                            ) : (
                              <b>{convertRupiah(this.state.infoBiaya.total)}</b>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr style={{ marginTop: "5px" }} />
                    <span style={{ fontSize: "13px", fontWeight: "bold" }}>
                      Biaya u/ {this.state.infoBiaya.Periode}
                    </span>
                    <br />
                    <span
                      style={{
                        fontSize: "13px",
                        fontWeight: "bold",
                        color: "green",
                      }}
                    >
                      {this.state.infoBiaya.disclaimer}. Info Lebih lanjut
                      hubungi melalui WA center.
                    </span>
                  </>
                ) : null}
                <p style={{ fontSize: "14px", margin: "15px 0 10px 0" }}>
                  <b>Nb: </b>Silahkan transfer ke{" "}
                  <a href="#info-rekening">rekening Institut Asia</a> sejumlah
                  total biaya diatas, selanjutnya upload bukti transfer pada
                  form dibawah berikut untuk diverifikasi.
                </p>
                <div
                  className="contact-form buktitf"
                  style={{ marginTop: "10px" }}
                >
                  <form>
                    <h3 className="title">Form Upload Bukti Transfer</h3>
                    <div className="form-input nomor">
                      <label>Nomor Pendaftaran</label>
                      <label
                        style={{
                          marginTop: "0px",
                          fontSize: "15px",
                          fontFamily: '"Poppins", sans-serif',
                          color: "yellow",
                          borderBottom: "2px solid ",
                          width: "100%",
                        }}
                      >
                        #{this.state.nomor_pendaftaran}
                      </label>
                      <span className="focus-input"></span>
                    </div>
                    <div className="form-input files">
                      <label>Bukti Transfer</label>
                      <img
                        src=""
                        alt=""
                        id="foto"
                        style={{ display: "none" }}
                      />
                      <label
                        className="filebt"
                        id="labelUpload"
                        htmlFor="bukti"
                      >
                        <span className="fas fa-upload"></span>
                        <i>Upload File</i>
                      </label>
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png,.PNG"
                        required
                        name="bukti"
                        id="bukti"
                        onChange={this.handleUploadPhoto}
                        hidden
                      />

                      <div className="responsive-validation">
                        <i id="fileinvalid" style={{ color: "orange" }}></i>
                        <i id="filevalid" style={{ color: "yellow" }}></i>
                      </div>
                    </div>
                    <button
                      className="btn"
                      type="button"
                      name="simpan"
                      id="simpan"
                      onClick={this.handleSubmit}
                    >
                      Kirim
                    </button>
                  </form>
                </div>
                <p style={{ fontSize: "14px", margin: "15px 0 10px 0" }}>
                  <b>Selama proses verifikasi </b> akan dihubungi lebih lanjut
                  melalui email & SMS.
                </p>
                <div className="contact konfirmasi">
                  <h2>Butuh Bantuan?</h2>
                  <hr />
                  <p>Scan Barcode berikut atau klik tombol di bawah ini</p>
                  <div className="flexverif">
                    <img src="../../asset/images/callwa.png" alt="" />
                    <div className="person">
                      <a
                        className="contact-person"
                        href="https://api.whatsapp.com/send?phone=6287881444088&text=Permisi%2C%20Saya%20ingin%20bertanya%20kak.%0A%0A"
                        target="_BLANK"
                        rel="noreferrer"
                      >
                        <i className="fab fa-whatsapp whatsapp"></i>
                        <h3>Whatsapp</h3>
                      </a>
                      &nbsp;&nbsp;
                      <a className="contact-person" href="tel:0341-478877">
                        <i className="fas fa-phone phone"></i>
                        <h3>Call. 0341-478877</h3>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contact-info right" id="info-rekening">
                <h1>Informasi Bank Transfer</h1>
                <div className="list-pembayaran">
                  <div className="list-desc">
                    <h4 className="title">Nama Terdaftar di Bank</h4>
                    <p>INSTITUT ASIA MALANG</p>
                  </div>
                  <hr />
                  <div className="list-desc">
                    <h4 className="title">Akun Bank</h4>
                    <div className="rekening">
                      <p>Mandiri</p>
                      <img src="../../asset/images/mandiri.jpg" alt="" />
                    </div>
                  </div>
                  <hr />
                  <div className="list-desc">
                    <h4 className="title">Nomor Rekening</h4>
                    <p>144-05-6788887-7</p>
                  </div>
                  <hr />

                  <div className="dropdown-info">
                    <button className="info-button">
                      Informasi Penting
                      <span className="fas fa-chevron-down"></span>
                    </button>
                    <div
                      className="info-content transform transform-active"
                      id="info"
                    >
                      <p>
                        <span>1.</span>
                        Pastikan Anda transfer ke nomor rekening tercantum via
                        mBanking, ATM atau Internet Banking.
                      </p>
                      <p>
                        <span>2.</span>
                        Pastikan nomor rekening tujuan telah sesuai.
                      </p>
                      <p>
                        <span>3.</span>
                        Kampus tidak bertanggung jawab apabila terjadi kesalahan
                        transfer dikarenakan kesalahan nomor rekening tujuan.
                      </p>
                      <p>
                        <span>4.</span>
                        Lakukan konfirmasi pada kontak yang sudah tertera
                        setelah melakukan pembayaran.
                      </p>
                      <p
                        style={{
                          padding: "20px",
                          backgroundColor: "#0c3f9e",
                          color: "#FFF",
                          borderRadius: "5px",
                        }}
                      >
                        <b>
                          <span>*</span>
                          Halaman ini dapat ditutup. Untuk melakukan pembayaran,
                          anda bisa mengeceknya kembali melalui link yang kami
                          kirim lewat email/wa anda.
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* The Modal */}
        {this.state.isPopup ? (
          <div className="modal">
            {/* Modal content */}
            <div className="modal-content">
              <p
                className="close"
                onClick={() => this.setState({ isPopup: false })}
              >
                ×
              </p>
              <div
                className="row"
                style={{ marginTop: "20px", marginBottom: "30px" }}
              >
                <div className="formContainer">
                  <fieldset className="fieldInput">
                    <input
                      className="form-input"
                      type="text"
                      placeholder="Kode Voucher..."
                      onChange={(e) =>
                        this.setState({ voucherInput: e.target.value })
                      }
                    />
                    <button
                      type="submit"
                      className="form-submit"
                      onClick={() =>
                        this.claimVoucher(this.state.voucherInput, "manual")
                      }
                    >
                      Klaim
                    </button>
                  </fieldset>
                </div>
                {this.state.voucher.map((list, i) => {
                  return (
                    <div className="column" key={i}>
                      <div
                        id={
                          this.state.kodeVoucher == list.kode_voucher
                            ? "non-active"
                            : null
                        }
                        className="coupon-card"
                      >
                        <p>
                          <b>{list.nama_voucher}</b>
                        </p>
                        <div className="coupon-row">
                          <span className="cpnCode">{list.kode_voucher}</span>

                          {this.state.kodeVoucher == list.kode_voucher ? (
                            <div
                              className="cpnBtn"
                              onClick={this.cancelVoucher}
                            >
                              <b>Cancel</b>
                            </div>
                          ) : (
                            <div
                              className="cpnBtn"
                              onClick={() =>
                                this.claimVoucher(list.kode_voucher, "auto")
                              }
                            >
                              <b>Klaim</b>
                            </div>
                          )}
                        </div>
                        <p>{convertRupiah(list.nominal_voucher)}</p>
                        <div className="circle1" />
                        <div className="circle2" />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
