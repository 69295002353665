import { BrowserRouter, Route, Switch } from 'react-router-dom';
import pendaftaran from './view/pendaftaran';
import konfirmasi from './view/konfirmasi';
import verifikasi from './view/verifikasi';
import buktipend from './view/buktipendaftaran';
import maintenance from './view/maintenance';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route path="/pendaftaran" exact component={pendaftaran} /> */}
        <Route path="/"  exact component={pendaftaran} />
        <Route path="/konfirmasi/pendaftaran/:conf" exact component={konfirmasi} />
        <Route path="/verifikasi/:conf" exact component={verifikasi} />
        <Route path="/biodata/camaba/:id" exact component={buktipend} />
      </Switch>
    </BrowserRouter>
  )
}

export default App;
