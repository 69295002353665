import React, { Component } from "react";
import API_akademik from "../services/akademik/Api_akademik";
import key_akademik from "../services/akademik/key_akademik";
import API_ASTOR from "../services/astor/Api_astor";
import KEY_ASTOR from "../services/astor/Key_astor";
import API_MHS from "../services/mahasiswa/API_MHS";
import Key_Mhs from "../services/mahasiswa/Key_Mhs";
import API_keuangan from "../services/keuangan/Api_keuangan";
import key_keuangan from "../services/keuangan/key_keuangan";

import formatPhone from "../molecules/formatPhone";
import formatText from "../molecules/formatText";
import validasiEmail from "../molecules/validasiEmail";
import removePlus from "../molecules/removePlus";
import cleansingPhone from "../molecules/cleansingPhone";
import GoogleTranslateButton from "../molecules/translate";

export default class pendaftaran extends Component {
  state = {
    // data option
    provinsiList: [],
    kotaList: [],
    kecamatanList: [],
    jurusanList: [],
    jalurList: [],

    // form registrasi
    formpost: {
      tahun_ajaran: "",
      tahun_angkatan: "",
      jalan: "",
      rt: "",
      rw: "",
      kdpos: "",
      kelurahan: "",
      kecamatan: "",
      kab: "",
      provinsi: "",
      codeNumber: "",
    },

    // input validasi
    isLainLain: false,
    isSMA: false,
    isPT: false,

    // popup
    isLoading: false,
    isSuccess: false,
    isDanger: false,
    title: "",
    subtitle: "",
  };

  getProvinsi = () => {
    API_ASTOR.get("set/wilayah/view" + KEY_ASTOR.baseURL).then((res) => {
      this.setState({ provinsiList: res.data[0].prof });
    });
  };

  getKota = () => {
    API_ASTOR.get(
      "set/wilayah/kota/view" +
        KEY_ASTOR.baseURL +
        "&ic=" +
        this.state.formpost.provinsi
    ).then((res) => {
      this.setState({
        kotaList: res.data[0].kota,
      });
    });
  };

  getKecamatan = (val) => {
    API_ASTOR.get(
      "set/wilayah/kecamatan/view" + KEY_ASTOR.baseURL + "&ic=" + val
    ).then((res) => {
      this.setState({
        kecamatanList: res.data[0].kecamatan,
      });
    });
  };

  getJurusan = () => {
    API_akademik.get("tampil/jurusan/view" + key_akademik.baseURL).then(
      (res) => {
        this.setState({ jurusanList: res.data });
      }
    );
  };

  getJalur = () => {
    API_akademik.get("tmpl/jalur/view" + key_akademik.baseURL).then((res) => {
      this.setState({ jalurList: res.data });
    });
  };

  getAjaranAktif = () => {
    API_akademik.get("daf/ajaran/aktif/view" + key_akademik.baseURL).then(
      (res) => {
        this.setState({
          formpost: {
            ...this.state.formpost,
            tahun_ajaran: res.data[0].ajrid,
            tahun_angkatan: res.data[0].ajrket,
          },
        });
      }
    );
  };

  onChangeProv = (e) => {
    this.setState(
      {
        formpost: {
          ...this.state.formpost,
          provinsi: e.target.value,
        },
      },
      () => {
        this.getKota();
      }
    );
  };

  onChangeKota = (e) => {
    this.setState(
      {
        formpost: {
          ...this.state.formpost,
          kab: e.target.value,
        },
      },
      () => {
        var data = this.state.kotaList;
        var val = this.state.formpost.kab;
        var result = data.filter(function (indeks) {
          return indeks.cname == val;
        });
        this.setState(
          {
            id_kota: result[0].ic,
          },
          () => {
            this.getKecamatan(this.state.id_kota);
          }
        );
      }
    );
  };

  onChangeWarga = (e) => {
    this.setState(
      {
        formpost: {
          ...this.state.formpost,
          kewarganegaraan: e.target.value,
        },
        citizenship: e.target.value,
      },
      () => {
        if (this.state.formpost.kewarganegaraan == "WNI") {
          this.setState({
            formpost: { ...this.state.formpost, codeNumber: "62" },
          });
        } else {
          this.setState({
            formpost: { ...this.state.formpost, codeNumber: "" },
          });
        }
      }
    );
  };

  onChangeInfo = (e) => {
    this.setState(
      {
        formpost: {
          ...this.state.formpost,
          info_asia: e.target.value,
        },
      },
      () => {
        if (this.state.formpost.info_asia == "Lain-Lain") {
          this.setState({ isLainLain: true });
        } else {
          this.setState({ isLainLain: false });
        }
      }
    );
  };

  onChangeAsalSekolahPT = (e) => {
    this.setState(
      {
        formpost: {
          ...this.state.formpost,
          asal_pendidikan: e.target.value,
        },
      },
      () => {
        let val = this.state.formpost.asal_pendidikan;
        if (val == "SMA Sederajat") {
          this.setState({
            isSMA: true,
            isPT: false,
            formpost: {
              ...this.state.formpost,
              nama_asal_pt: "",
              tahun_lulus_pt: "",
              jurusan_pt: "",
              alamat_pt: "",
            },
          });
        } else if (
          val == "D1" ||
          val == "D2" ||
          val == "D3" ||
          val == "D4" ||
          val == "Pindahan dari PT Lain" ||
          val == "Pengalaman Kerja" ||
          val == "Akademi Komunitas" ||
          val == "Pindahan dari PT Lain"
        ) {
          this.setState({
            isSMA: false,
            isPT: true,
          });
        } else {
          this.setState({
            isSMA: false,
            isPT: false,
            formpost: {
              ...this.state.formpost,
              nama_asal_pt: "",
              tahun_lulus_pt: "",
              jurusan_pt: "",
              alamat_pt: "",
              nama_asal_sekolah: "",
              tahun_lulus_sekolah: "",
              jurusan_sekolah: "",
              alamat_sekolah: "",
            },
          });
        }
      }
    );
  };

  onChangeInput = (e) => {
    this.setState({
      formpost: {
        ...this.state.formpost,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const newForm = {
      ...this.state.formpost,
      nowa:
        removePlus(this.state.formpost.codeNumber) +
        cleansingPhone(this.state.formpost.nowa),
      nomor_kerabat:
        removePlus(this.state.formpost.codeNumber) +
        cleansingPhone(this.state.formpost.nomor_kerabat),
    };
    this.setState({ isLoading: true });
    const resRegister = await API_MHS.post(
      "core-mhs/register" + Key_Mhs.baseURL,
      newForm
    );
    if (resRegister.status == 200) {
      if (resRegister.data[0].response_code == 1) {
        this.setState(
          {
            formpost: {
              ...this.state.formpost,
              nowa:
                removePlus(this.state.formpost.codeNumber) +
                cleansingPhone(this.state.formpost.nowa),
              nomor_kerabat:
                removePlus(this.state.formpost.codeNumber) +
                cleansingPhone(this.state.formpost.nomor_kerabat),
              no_daftar: resRegister.data[0].no_pendaftaran,
            },
          },
          async () => {
            setTimeout(() => {
              this.setState({ isLoading: false, isSuccess: true });
              window.location.replace(
                `/verifikasi/${resRegister.data[0].no_pendaftaran}`
              );
            }, 2000);
            await API_keuangan.post(
              "mail/cc/fo/send" + key_keuangan.baseURL,
              this.state.formpost
            );
          }
        );
      } else if (resRegister.data[0].response_code == 2) {
        this.setState({
          isDanger: true,
          isLoading: false,
          title: "Email Tidak Valid !",
          subtitle: "Silahkan masukkan email yang valid.",
        });
      } else if (resRegister.data[0].response_code == "DB") {
        this.setState({
          isDanger: true,
          isLoading: false,
          title: "Pemberitahuan!",
          subtitle:
            "Email anda sudah melakukan registrasi. Akan kami alihkan otomatis dalam 3s ke halaman konfirmasi.",
        });
        setTimeout(function () {
          window.location.replace(
            `/konfirmasi/pendaftaran/${resRegister.data[0].no_pendaftaran}`
          );
        }, 3000);
      } else if (resRegister.data[0].response_code == "DU") {
        this.setState({
          isDanger: true,
          isLoading: false,
          title: "Pemberitahuan!",
          subtitle:
            "Email anda sudah melakukan daftar ulang. Hubungi WA Center Institut Asia untuk informasi lebih lanjut.",
        });
      } else {
        this.setState({
          isDanger: true,
          isLoading: false,
          title: "Pendaftaran Gagal!",
          subtitle: "Tolong periksa kembali data anda.",
        });
      }
    } else {
      this.setState({
        isLoading: false,
        isDanger: true,
        title: "Pendaftaran Gagal!",
        subtitle: "Tolong periksa kembali data anda.",
      });
    }
  };

  componentDidMount() {
    sessionStorage.clear();
    localStorage.clear();
    this.getProvinsi();
    this.getJurusan();
    this.getJalur();
    this.getAjaranAktif();
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <div id="preloader">
            <div className="jumper">
              <li>
                <span className="fa fa-spinner fa-spin"></span>&nbsp;{" "}
                <span>Mengirim Data . . .</span>
              </li>
            </div>
          </div>
        ) : null}

        {/* popup info  */}
        {this.state.isSuccess ? (
          <div className="popbox active">
            <div className="popup-box active">
              <div className="icon">
                <i className="fa fa-check" />
              </div>
              <header>Pendaftaran Berhasil !</header>
              <div className="pop-load">
                <div className="muter">
                  <span className="fa fa-spinner fa-spin" />
                  &nbsp;&nbsp;&nbsp;<span>memuat halaman verifikasi . . .</span>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* popup danger  */}
        {this.state.isDanger ? (
          <div className="popbox-warning active">
            <div className="popup-box-warning active">
              <div className="icon">
                <i className="fas fa-exclamation" />
              </div>
              <h1>{this.state.title}</h1>
              <p>{this.state.subtitle}</p>
              <label onClick={() => this.setState({ isDanger: false })}>
                OK
              </label>
            </div>
          </div>
        ) : null}

        <div className="container">
          <span className="big-circle"></span>
          <img src="./asset/images/shape.png" className="square" alt="true" />
          <div className="form">
            <div className="contact-info">
              <div className="contact-header">
                <img src="./asset/images/logo_pendaftaran.png" alt="true" />
                <div className="contact-title">
                  <h3 className="title">Formulir Pendaftaran Online</h3>
                  <h3 className="title2">( Online Registration Form )</h3>
                </div>
              </div>
              <p className="text">
                Isilah data-data berikut ini dengan lengkap untuk memudahkan
                proses Pendaftaran Online.
              </p>
              <div className="info">
                <div className="information">
                  <img
                    src="./asset/images/footer-icon-home.png"
                    className="icon"
                    alt="true"
                  />
                  <p>Jl. Soekarno Hatta - Rembuksari 1A Malang, 65113</p>
                </div>
                <div className="information">
                  <img
                    src="./asset/images/footer-icon-email.png"
                    className="icon"
                    alt="true"
                  />
                  <p>fo@asia.ac.id</p>
                </div>
                <div className="information">
                  <img
                    src="./asset/images/footer-icon-phone.png"
                    className="icon"
                    alt="true"
                  />
                  <p>
                    Telp. 0341 - 478877 (Hunting) | Fax. 0341 - 4345225 |
                    Whatsapp. 0878-814-440-88
                  </p>
                </div>
                <p className="text">
                  Setelah mengisi data-data berikut, kami akan segera
                  menghubungi Anda untuk melakukan verifikasi data.
                </p>
                <p className="text">
                  Anda dapat menghubungi Sekretariat Pendaftaran Mahasiswa Baru
                  di Kampus Pusat Institut Teknologi dan Bisnis ASIA Malang di
                  Jl. Soekarno Hatta - Rembuksari 1A Malang
                </p>
              </div>
              <div className="social-media">
                <p>Info Lebih Lanjut :</p>
                <div className="social-icons">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/institutasiamalang"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a target="_blank" href="https://twitter.com/institut_asia">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/institut_asia/"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UClmjiKomfiJlWI3tM1kU5hg"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
                <br/>
                <GoogleTranslateButton/>
              </div>
            </div>

            <div className="contact-form">
              <span className="circle one"></span>
              <span className="circle two"></span>
              <form onSubmit={this.handleSubmit}>
                <h3 className="title">Formulir Pendaftaran</h3>
                <div className="form-input name">
                  <label>Nama Lengkap</label>
                  <input
                    type="text"
                    onKeyUp={formatText}
                    name="nama"
                    id="nama"
                    placeholder="contoh: Rio Aradona"
                    onChange={this.onChangeInput}
                    required
                  />
                  <span className="focus-input"></span>
                </div>
                <div className="wrap-input">
                  <div className="form-wrap-input">
                    <label>Tempat Lahir</label>
                    <input
                      type="text"
                      name="tempat_lahir"
                      placeholder="contoh: Malang"
                      onChange={this.onChangeInput}
                      required
                    />
                    <span className="focus-input"></span>
                  </div>
                  <div className="form-wrap-input">
                    <label>Tanggal Lahir</label>
                    <input
                      name="tgl_lahir"
                      type="date"
                      onChange={this.onChangeInput}
                      required
                    />
                    <span className="focus-input"></span>
                  </div>
                </div>
                <div className="wrap-input">
                  <div className="form-input-select">
                    <label>Jenis Kelamin</label>
                    <select
                      name="gender"
                      id="jk"
                      required
                      onChange={this.onChangeInput}
                      style={{ marginTop: "0px" }}
                    >
                      <option value="">- Pilih Jenis Kelamin -</option>
                      <option value="1">Laki-laki</option>
                      <option value="2">Perempuan</option>
                    </select>
                  </div>
                  <div className="form-input-select">
                    <label>Agama</label>
                    <select
                      name="agama"
                      required
                      id="agama"
                      onChange={this.onChangeInput}
                    >
                      <option value="">- Pilih Agama -</option>
                      <option value="Islam">Islam</option>
                      <option value="Kristen">Kristen</option>
                      <option value="Katholik">Katholik</option>
                      <option value="Hindu">Hindu</option>
                      <option value="Budha">Budha</option>
                      <option value="Konghucu">Konghucu</option>
                    </select>
                  </div>
                </div>

                <div className="form-input-select">
                  <label>Kewarganegaraan</label>
                  <select
                    name="kewarganegaraan"
                    className="form-control"
                    id="exampleFormControlSelect1"
                    onChange={this.onChangeWarga}
                  >
                    <option value="" hidden>
                      - Pilih Kewarganegaraan-
                    </option>
                    <option value="WNI">Warga Negara Indonesia (WNI)</option>
                    <option value="WNA">Warga Negara Asing (WNA)</option>
                  </select>
                </div>

                {this.state.citizenship == "WNI" ? (
                  <>
                    <div className="wrap-input">
                      <div className="form-input-select">
                        {/* provinsi  */}
                        <label>Provinsi</label>
                        <select
                          name="provinsi"
                          onChange={this.onChangeProv}
                          className="form-control"
                          required
                        >
                          <option value="" hidden>
                            - Pilih Provinsi -
                          </option>
                          {this.state.provinsiList.map((list, i) => {
                            return (
                              <option key={i} value={list.i}>
                                {list.pname}
                              </option>
                            );
                          })}
                        </select>
                        <span className="focus-input"></span>
                      </div>

                      {/* KOTA */}
                      <div className="form-input-select">
                        <label>Kota/Kabupaten</label>
                        <select
                          name="kab"
                          className="form-control"
                          onChange={this.onChangeKota}
                          required
                        >
                          <option value="" hidden>
                            - Pilih Kota/Kab -
                          </option>
                          {this.state.kotaList.map((list, i) => {
                            return (
                              <option key={i} value={list.cname}>
                                {list.cname}
                              </option>
                            );
                          })}
                        </select>
                        <span className="focus-input"></span>
                      </div>
                    </div>
                    <div className="wrap-input">
                      {/* KECAMATAN  */}
                      <div className="form-input-select">
                        <label>Kecamatan</label>
                        <select
                          name="kecamatan"
                          className="form-control"
                          onChange={this.onChangeInput}
                          required
                        >
                          <option value="" hidden>
                            - Pilih Kecamatan -
                          </option>
                          {this.state.kecamatanList.map((list, i) => {
                            return (
                              <option key={i} value={list.dname}>
                                {list.dname}
                              </option>
                            );
                          })}
                        </select>
                        <span className="focus-input"></span>
                      </div>
                      {/* DESA  */}
                      <div className="form-wrap-input">
                        <label>Kelurahan/Desa</label>
                        <input
                          type="text"
                          name="kelurahan"
                          className="form-control"
                          placeholder="contoh: Tlogomas"
                          required
                          onChange={this.onChangeInput}
                        />
                        <span className="focus-input"></span>
                      </div>
                    </div>

                    <div
                      className="wrap-input"
                      style={{ marginBottom: "10px" }}
                    >
                      {/* RT  */}
                      <div className="form-wrap-input">
                        <label>RT</label>
                        <input
                          type="number"
                          name="rt"
                          maxLength={3}
                          className="form-control"
                          placeholder="contoh: 01"
                          required
                          onChange={this.onChangeInput}
                        />
                        <span className="focus-input"></span>
                      </div>

                      {/* RW  */}
                      <div className="form-wrap-input">
                        <label>RW</label>
                        <input
                          type="number"
                          name="rw"
                          maxLength={3}
                          className="form-control"
                          placeholder="contoh: 06"
                          required
                          onChange={this.onChangeInput}
                        />
                        <span className="focus-input"></span>
                      </div>

                      {/* KODE POS  */}
                      <div className="form-wrap-input">
                        <label>Kode POS</label>
                        <input
                          type="text"
                          name="kdpos"
                          className="form-control"
                          placeholder="contoh: 47361"
                          onChange={this.onChangeInput}
                        />
                        <span className="focus-input"></span>
                      </div>
                    </div>

                    {/* Alamat  */}
                    <div className="form-input name">
                      <label>Alamat</label>
                      <textarea
                        type="text"
                        name="jalan"
                        className="form-control"
                        placeholder="contoh: Jl. Tlogomas No. 24"
                        required
                        onChange={this.onChangeInput}
                      />
                      <span className="focus-input"></span>
                    </div>
                  </>
                ) : this.state.citizenship == "WNA" ? (
                  <div className="form-input name">
                    <label>Address</label>
                    <textarea
                      type="text"
                      name="jalan"
                      className="form-control"
                      placeholder="example: 7 Anson Road, 87 International Rd, #07- 078603, Singapore 619622"
                      required
                      onChange={this.onChangeInput}
                    />
                    <span className="focus-input"></span>
                  </div>
                ) : null}

                <div className="wrap-input">
                  {/* email  */}
                  <div className="form-wrap-input">
                    <label>Email</label>
                    <input
                      type="text"
                      onKeyUp={validasiEmail}
                      name="email"
                      id="email"
                      placeholder="contoh: admin@asia.com"
                      onChange={this.onChangeInput}
                      required
                    />
                    <span className="focus-input"></span>
                  </div>

                  {/* no Telepon */}
                  <div className="form-wrap-input">
                    <label>No. Telepon / WA Aktif</label>
                    <input
                      type="text"
                      className="kode-phone"
                      placeholder="+XX"
                      name="codeNumber"
                      onChange={this.onChangeInput}
                      value={this.state.formpost.codeNumber}
                      required
                      disabled={
                        this.state.formpost.kewarganegaraan == "WNI"
                          ? true
                          : false
                      }
                    />
                    <input
                      className="no-phone"
                      type="text"
                      onKeyUp={formatPhone}
                      name="nowa"
                      id="notel"
                      min="0"
                      placeholder="ex: 83111038787"
                      onChange={this.onChangeInput}
                      required
                    />
                    <span className="focus-input"></span>
                  </div>
                  <div className="responsive-validation">
                    <i id="emailinvalid" style={{ color: "orange" }}></i>
                    <i id="emailvalid" style={{ color: "yellow" }}></i>
                  </div>
                </div>

                <div className="wrap-input">
                  {/* facebook  */}
                  <div className="form-wrap-input">
                    <label>Facebook</label>
                    <input
                      type="text"
                      name="fb"
                      id="fb"
                      placeholder="contoh: Rio Aradona"
                      onChange={this.onChangeInput}
                      required
                    />
                    <span className="focus-input"></span>
                  </div>

                  {/* kaos */}
                  <div className="form-input-select">
                    <label>Kaos</label>
                    <select
                      name="kaos"
                      id="exampleFormControlSelect1"
                      onChange={this.onChangeInput}
                    >
                      <option value="" hidden>
                        - Pilih Ukuran-
                      </option>
                      <option value="S">S</option>
                      <option value="M">M</option>
                      <option value="L">L</option>
                      <option value="XL">XL</option>
                      <option value="XXL">XXL</option>
                      <option value="Jumbo(Khusus)">Jumbo(Khusus)</option>
                    </select>
                    <span className="focus-input"></span>
                  </div>
                </div>

                {/* almet */}
                <div className="form-input-select">
                  <label>Jas Almamater</label>
                  <select
                    name="almet"
                    id="exampleFormControlSelect1"
                    onChange={this.onChangeInput}
                  >
                    <option value="" hidden>
                      - Pilih Ukuran-
                    </option>
                    <option value="S">S</option>
                    <option value="M">M</option>
                    <option value="L">L</option>
                    <option value="XL">XL</option>
                    <option value="XXL">XXL</option>
                    <option value="Jumbo(Khusus)">Jumbo(Khusus)</option>
                  </select>
                  <span className="focus-input"></span>
                </div>

                {/* info asia  */}
                <div className="form-input-select">
                  <label>Info Tentang Asia Dari</label>
                  <select
                    name="info_asia"
                    required
                    onChange={this.onChangeInfo}
                  >
                    <option value="">-- Pilih Informasi --</option>
                    <option value="SOSIALISASI">SOSIALISASI</option>
                    <option value="DOSEN/KARYAWAN ASIA">
                      DOSEN/KARYAWAN ASIA
                    </option>
                    <option value="MAHASISWA ASIA">MAHASISWA ASIA</option>
                    <option value="ALUMNI ASIA">ALUMNI ASIA</option>
                    <option value="KERABAT/KAKAK">KERABAT/KAKAK</option>
                    <option value="BROSUR">BROSUR</option>
                    <option value="INSTAGRAM">INSTAGRAM</option>
                    <option value="YOUTUBE">YOUTUBE</option>
                    <option value="TIKTOK">TIKTOK</option>
                    <option value="KORAN">KORAN</option>
                    <option value="RADIO">RADIO</option>
                    <option value="WEBSITE">WEBSITE</option>
                    <option value="Lain-Lain">Lain-Lain</option>
                  </select>
                  <span className="focus-input"></span>
                </div>

                {/* info lain lain  */}
                {this.state.isLainLain ? (
                  <div className="form-wrap-input">
                    <label>Info Dari</label>
                    <input
                      type="text"
                      name="info_asia"
                      placeholder="contoh: Mas Wawan Marketing"
                      onChange={this.onChangeInput}
                      required
                    />
                    <span className="focus-input"></span>
                  </div>
                ) : null}

                {/* kontak kerabat  */}
                <div className="kontak-kerabat">
                  <div className="form-wrap-input">
                    <label>Kontak Kerabat</label>
                    <input
                      type="text"
                      name="nama_kerabat"
                      id="nmkerabat"
                      placeholder="Nama Kerabat"
                      onChange={this.onChangeInput}
                      required
                    />
                    <span className="focus-input"></span>
                  </div>
                  <div className="wrap-input" style={{ marginTop: "25px" }}>
                    <div className="form-wrap-input">
                      <input
                        type="text"
                        className="kode-phone"
                        placeholder="+XX"
                        name="codeNumber"
                        onChange={this.onChangeInput}
                        value={this.state.formpost.codeNumber}
                        required
                        disabled={
                          this.state.formpost.kewarganegaraan == "WNI"
                            ? true
                            : false
                        }
                      />
                      <input
                        type="text"
                        className="no-phone"
                        min="0"
                        onKeyUp={formatPhone}
                        name="nomor_kerabat"
                        id="notel2"
                        placeholder="Telepon Kerabat"
                        onChange={this.onChangeInput}
                        required
                      />
                      <span className="focus-input"></span>
                    </div>
                    <div className="form-wrap-input">
                      <input
                        type="text"
                        name="hubungan_kerabat"
                        id="hubkerabat"
                        placeholder="Hubungan Kerabat"
                        className="hubkerabat"
                        onChange={this.onChangeInput}
                        required
                      />
                      <span className="focus-input"></span>
                    </div>
                  </div>
                </div>

                {/* prodi  */}
                <div className="form-input-select studi">
                  <label>Pilihan Program Studi</label>
                  <select required name="prodi" onChange={this.onChangeInput}>
                    <option value="">- Pilih Prodi -</option>
                    {this.state.jurusanList.map((list, i) => {
                      return (
                        <option key={i} value={list.idakd}>
                          {list.nmakd}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* pilihan kelas  */}
                <div className="wrap-input">
                  <div className="form-input-select kelas">
                    <label>Pilihan Kelas</label>
                    <select required name="jalur" onChange={this.onChangeInput}>
                      <option value="">- Pilih Kelas -</option>
                      {this.state.formpost.prodi == "4-1"
                        ? this.state.jalurList
                            .filter((item) => item.nmjlr === "Reguler")
                            .map((list, i) => {
                              return (
                                <option key={i} value={list.idjlr}>
                                  {list.nmjlr}
                                </option>
                              );
                            })
                        : this.state.jalurList.map((list, i) => {
                            return (
                              <option key={i} value={list.idjlr}>
                                {list.nmjlr}
                              </option>
                            );
                          })}
                    </select>
                  </div>

                  {/* asal sekolah  */}
                  <div className="form-input-select">
                    <label>Asal Sekolah / Perguruan Tinggi</label>
                    <select
                      required
                      name="asal_pendidikan"
                      id="awkolHaL"
                      onChange={this.onChangeAsalSekolahPT}
                    >
                      <option value="">- Asal Sekolah -</option>
                      <option value="SMA Sederajat">SMA Sederajat</option>
                      <option value="D1">D1</option>
                      <option value="D2">D2</option>
                      <option value="D3">D3</option>
                      <option value="Pengalaman Kerja">Pengalaman Kerja</option>
                      <option value="Akademi Komunitas">
                        Akademi Komunitas
                      </option>
                      <option value="Pindahan dari PT Lain">
                        Pindahan dari PT Lain
                      </option>
                    </select>
                  </div>
                </div>

                {this.state.isSMA || this.state.isPT ? (
                  <>
                    {/* nama sekolah */}
                    <div className="form-wrap-input name">
                      <label>Nama Asal Sekolah (SMA Sederajat)</label>
                      <input
                        type="text"
                        id="namasekolah_camaba"
                        placeholder="contoh: SMA 09 Jakarta"
                        className=""
                        required
                        onChange={this.onChangeInput}
                        name="nama_asal_sekolah"
                      />
                      <span className="focus-input"></span>
                    </div>

                    {/* tahun lulus*/}
                    <div className="form-wrap-input">
                      <label>Tahun Lulus (SMA Sederajat)</label>
                      <input
                        type="number"
                        min="0"
                        name="tahun_lulus_sekolah"
                        id="lulussekolah_camaba"
                        placeholder="contoh: 2012"
                        className="lulussekolah_camaba"
                        onChange={this.onChangeInput}
                        required
                        onInput={(e) => {
                          e.target.value = e.target.value.slice(0, 4);
                        }}
                      />
                      <span className="focus-input"></span>
                    </div>

                    {/* jurusan sekolah*/}
                    <div className="form-wrap-input">
                      <label>Jurusan Asal (SMA Sederajat)</label>
                      <input
                        type="text"
                        name="jurusan_sekolah"
                        id="jurusanasal_camaba"
                        placeholder="contoh: Multimedia"
                        className="jurusanasal_camaba"
                        onChange={this.onChangeInput}
                        required
                      />
                      <span className="focus-input"></span>
                    </div>

                    {/* alamat sekolah  */}
                    <div className="form-wrap-input name">
                      <label>Alamat Sekolah (SMA Sederajat)</label>
                      <input
                        type="text"
                        name="alamat_sekolah"
                        id="alamatsekolah_camaba"
                        placeholder="contoh: Jl. Soekarno - Hatta Jl. Rembuksari No.1 A, Mojolangu"
                        className="alamatsekolah_camaba"
                        onChange={this.onChangeInput}
                        required
                      />
                      <span className="focus-input"></span>
                    </div>
                  </>
                ) : null}

                {this.state.isPT ? (
                  <>
                    {/* nama sekolah */}
                    <div className="form-wrap-input name">
                      <label>Nama Asal Perguruan Tinggi</label>
                      <input
                        type="text"
                        id="namasekolah_camaba"
                        placeholder="contoh: Universitas Syach Buana"
                        className=""
                        required
                        onChange={this.onChangeInput}
                        name="nama_asal_pt"
                      />
                      <span className="focus-input"></span>
                    </div>

                    {/* tahun lulus*/}
                    <div className="form-wrap-input">
                      <label>Tahun Lulus (Perguruan Tinggi)</label>
                      <input
                        type="number"
                        min="0"
                        name="tahun_lulus_pt"
                        id="lulussekolah_camaba"
                        placeholder="contoh: 2012"
                        className="lulussekolah_camaba"
                        onChange={this.onChangeInput}
                        required
                        onInput={(e) => {
                          e.target.value = e.target.value.slice(0, 4);
                        }}
                      />
                      <span className="focus-input"></span>
                    </div>

                    {/* jurusan sekolah*/}
                    <div className="form-wrap-input">
                      <label>Jurusan Asal (Perguruan Tinggi)</label>
                      <input
                        type="text"
                        name="jurusan_pt"
                        id="jurusanasal_camaba"
                        placeholder="contoh: Teknik Informatika"
                        className="jurusanasal_camaba"
                        onChange={this.onChangeInput}
                        required
                      />
                      <span className="focus-input"></span>
                    </div>

                    {/* alamat sekolah  */}
                    <div className="form-wrap-input name">
                      <label>Alamat Perguruan Tinggi</label>
                      <input
                        type="text"
                        name="alamat_pt"
                        id="alamatsekolah_camaba"
                        placeholder="contoh: Jl. Soekarno - Hatta Jl. Rembuksari No.1 A, Mojolangu"
                        className="alamatsekolah_camaba"
                        onChange={this.onChangeInput}
                        required
                      />
                      <span className="focus-input"></span>
                    </div>
                  </>
                ) : null}
                <p style={{ color: "white", marginTop: "20px" }}>
                  *pastikan data sudah diteliti dengan benar.
                </p>
                <p align="right">
                  <button
                    className="btn"
                    type="submit"
                    name="simpan"
                    id="simpan"
                    style={{ marginTop: "30px" }}
                  >
                    Registrasi
                  </button>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
