import React, { Component } from 'react'

export default class maintenance extends Component {
  render() {
    return (
      <div>
            <img src="https://wallpapers.com/images/hd/site-down-maintenance-offline-6yva59whysxtlzm6.jpg"></img>
      </div>
    )
  }
}
