import React, { Component } from "react";
import API_keuangan from "../services/keuangan/Api_keuangan";
import key_keuangan from "../services/keuangan/key_keuangan";

export default class verif extends Component {
  state = {
    // popup
    isLoading: false,
    isSuccess: false,
    isDanger: false,
    title: "",
    subtitle: "",
  };

  gantiisi = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  kirimkode = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const resCode = await API_keuangan.post(
      "cek/kode/token/v" + key_keuangan.baseURL + "&u0=" + this.props.match.params.conf,
      this.state
    );
    if (resCode.data[0].cekingdata == 1) {
      this.setState({ isSuccess: true, isLoading: false });
      window.location.replace(
        `/konfirmasi/pendaftaran/${this.props.match.params.conf}`
      );
    } else {
      this.setState({
        isDanger: true,
        title: "Verifikasi Kode Gagal !",
        subtitle:
          "silahkan masukkan kode yang benar sesuai dengan yang telah kita kirim di email.",
        isLoading: false,
      });
    }
  };

  Verif(e) {
    var target = e.srcElement || e.target;
    var maxLength = parseInt(target.attributes["maxlength"].value, 10);
    var myLength = target.value.length;
    if (myLength >= maxLength) {
      var next = target;
      while ((next = next.nextElementSibling)) {
        if (next == null) break;
        if (next.tagName.toLowerCase() === "input") {
          next.focus();
          break;
        }
      }
    } else if (myLength === 0) {
      var previous = target;
      while ((previous = previous.previousElementSibling)) {
        if (previous == null) break;
        if (previous.tagName.toLowerCase() === "input") {
          previous.focus();
          break;
        }
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <div id="preloader">
            <div className="jumper">
              <li>
                <span className="fa fa-spinner fa-spin"></span>&nbsp;{" "}
                <span>Mengirim Data . . .</span>
              </li>
            </div>
          </div>
        ) : null}

        {/* popup info  */}
        {this.state.isSuccess ? (
          <div className="popbox active">
            <div className="popup-box active">
              <div className="icon">
                <i className="fa fa-check" />
              </div>
              <header>Verifikasi Berhasil !</header>
              <div className="pop-load">
                <div className="muter">
                  <span className="fa fa-spinner fa-spin" />
                  &nbsp;&nbsp;&nbsp;<span>memuat halaman konfirmasi . . .</span>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* popup danger  */}
        {this.state.isDanger ? (
          <div className="popbox-warning active">
            <div className="popup-box-warning active">
              <div className="icon">
                <i className="fas fa-exclamation" />
              </div>
              <h1>{this.state.title}</h1>
              <p>{this.state.subtitle}</p>
              <label onClick={() => this.setState({ isDanger: false })}>
                OK
              </label>
            </div>
          </div>
        ) : null}

        <div className="container pembayaran">
          <span className="big-circle"></span>
          <img src="../../asset/images/shape.png" className="square" />
          <div className="form">
            <div className="contact-info pembayaran verif">
              <h1>Kode Verifikasi Pendaftaran</h1>
              <div className="form-buktitf">
                <form autoComplete="off" onSubmit={this.kirimkode}>
                  <div className="ashiapdesc">
                    <span className="fad fa-exclamation-circle"></span>
                    <p>
                      Silahkan cek kode verifikasi yang sudah dikirim ke <b>Email/SMS</b>
                      anda
                    </p>
                  </div>
                  <div className="form-input kode">
                    <label>Kode Verifikasi</label>
                    <div className="kodeverif" onKeyUp={this.Verif}>
                      <input
                        type="text"
                        required
                        name="ka"
                        onChange={this.gantiisi}
                        maxLength="1"
                      ></input>
                      &nbsp;
                      <input
                        type="text"
                        required
                        name="kb"
                        onChange={this.gantiisi}
                        maxLength="1"
                      ></input>
                      &nbsp;
                      <input
                        type="text"
                        required
                        name="kc"
                        onChange={this.gantiisi}
                        maxLength="1"
                      ></input>
                      &nbsp;
                      <input
                        type="text"
                        required
                        name="kd"
                        onChange={this.gantiisi}
                        maxLength="1"
                      ></input>
                      &nbsp;
                      <input
                        type="text"
                        required
                        name="ke"
                        onChange={this.gantiisi}
                        maxLength="1"
                      ></input>
                      &nbsp;
                      <input
                        type="text"
                        required
                        name="kf"
                        onChange={this.gantiisi}
                        maxLength="1"
                      ></input>
                      &nbsp;
                    </div>
                  </div>
                  <div className="btn-verif">
                    <button type="submit">Kirim</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="contact-info right verif">
              <h2>Butuh Bantuan?</h2>
              <hr />
              <p className="judulp">
                Scan Barcode atau klik tombol di bawah ini
              </p>
              <div className="flexverif">
                <img src="../../asset/images/callwa.png" />
                <div className="person">
                  <a
                    className="contact-person"
                    href="https://api.whatsapp.com/send?phone=6287881444088&text=Permisi%2C%20Saya%20ingin%20bertanya%20kak.%0A%0A"
                    target="_BLANK"
                  >
                    <i className="fab fa-whatsapp whatsapp"></i>
                    <h3>WhatsApp</h3>
                  </a>
                  &nbsp;&nbsp;
                  <a className="contact-person" href="tel:0341-478877">
                    <i className="fas fa-phone phone"></i>
                    <h3>Call. 0341-478877</h3>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
