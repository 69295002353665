import React, { Component } from "react";
import API_keuangan from "../services/keuangan/Api_keuangan";
import key_keuangan from "../services/keuangan/key_keuangan";
import UrlKeuangan from "../services/keuangan/koneksi_keuangan";

export default class buktipendaftaran extends Component {
  state = {
    biodata: [],
  };

  getBiodata = async () => {
    const resBiodata = await API_keuangan.get(
      "biodata/camaba" +
        key_keuangan.baseURL +
        "&nodaftar=" +
        this.props.match.params.id
    );
    if (resBiodata) {
      this.setState({ biodata: resBiodata.data });
    }
  };

  componentDidMount() {
    this.getBiodata();
  }

  render() {
    return (
      <div className="container buktipend">
        <span className="big-circle" />
        <img src="./asset/images/shape.png" className="square" alt="" />
        <div className="buktitf-section">
          <div className="buktitf-header">
            <div className="sideleft">
              <div className="logo">
                <img
                  src="https://i.ibb.co/Fw40mk3/logo-pendaftaran.png"
                  alt=""
                />
                <div className="logo-contact">
                  <h3>Registrasi Online</h3>
                  <h3>(Online Registration)</h3>
                </div>
              </div>
              <div className="address">
                <p>Jl. Soekarno Hatta - Rembuksari 1A Malang, 65113</p>
                <p>Telp. 0341 - 478877 (Hunting) | Fax. 0341 - 4345225</p>
                <p>fo@asia.ac.id</p>
              </div>
            </div>
            <div className="sideright">
              <h1>Informasi Registrasi</h1>
              <div className="sideright-desc">
                <p>
                  Halaman ini adalah berisi tentang informasi registrasi, anda
                  dapat membuka kembali halaman ini pada link yang dikirim di
                  email/WA
                </p>
                <div className="button">
                  <button hidden>
                    <i className="fas fa-file-download" />
                    &nbsp;Download
                  </button>
                  <button
                    onClick={(e) => {
                      window.print(e);
                    }}
                  >
                    <i className="fas fa-sticky-note" />
                    &nbsp;Cetak Info
                  </button>
                </div>
              </div>
            </div>
          </div>
          {this.state.biodata.status == "A" ? (
            <h2 style={{textAlign: 'center', margin: '10px 0 50px 0'}}>Selamat Anda Sudah Menjadi Mahasiswa Institut Asia 🎊</h2>
          ) : (
            <>
              {/* Start Content */}
              <div className="buktitf-content">
                <div className="data-diri-content">
                  <div className="buktitf-content-left">
                    <h3 className="titles w">Data Diri</h3>
                    <div className="data-diri">
                      <div className="data-diri1">
                        <label>Nama Lengkap</label>
                        <li>{this.state.biodata.nama}</li>
                      </div>
                      <div className="data-diri1">
                        <label>Tempat/Tanggal Lahir</label>
                        <li>{this.state.biodata.ttl}</li>
                      </div>
                    </div>
                    <div className="data-diri">
                      <div className="data-diri1">
                        <label>Jenis Kelamin</label>
                        <li>{this.state.biodata.gender}</li>
                      </div>
                    </div>
                    <h3 className="titles x">Pendidikan</h3>
                    <div className="data-diri">
                      <div className="data-diri1 asal-pendidikan">
                        <label>Asal Pendidikan</label>
                        <li>{this.state.biodata.asalpendidikan}</li>
                      </div>
                    </div>
                    <h3 className="titles y">Kontak</h3>
                    <div className="data-diri">
                      <div className="data-diri1">
                        <label>Email</label>
                        <li>{this.state.biodata.email}</li>
                      </div>
                      <div className="data-diri1">
                        <label>No. Telp</label>
                        <li>+{this.state.biodata.nohp}</li>
                      </div>
                    </div>
                    <div className="data-diri">
                      <div className="data-diri1">
                        <label>Facebook</label>
                        <li>{this.state.biodata.fb}</li>
                      </div>
                      <div className="data-diri1">
                        <label>Telepon Kerabat</label>
                        <li>+{this.state.biodata.nohpkerabat}</li>
                      </div>
                    </div>
                    <div className="data-diri">
                      <div className="data-diri1">
                        <label>Nama Kerabat</label>
                        <li>{this.state.biodata.namakerabat}</li>
                      </div>
                      <div className="data-diri1">
                        <label>Hubungan Kerabat</label>
                        <li>{this.state.biodata.hubungankerabat}</li>
                      </div>
                    </div>
                    <h3 className="titles x">Rencana Studi</h3>
                    <div className="data-diri">
                      <div className="data-diri1">
                        <label>Pilihan Program Studi</label>
                        <li>{this.state.biodata.jurusan}</li>
                      </div>
                      <div className="data-diri1">
                        <label>Pilihan Kelas</label>
                        <li>{this.state.biodata.kelas}</li>
                      </div>
                    </div>
                  </div>
                  <div className="screenshot-buktitf">
                    <h4 className="title-tf-ss">
                      Bukti Transfer{" "}
                      {this.state.biodata.status == "D"
                        ? "Pendaftaran"
                        : this.state.biodata.status == "DU"
                        ? "Daftar Ulang"
                        : null}{" "}
                      :
                    </h4>
                    <img
                      className="img-tf"
                      src={UrlKeuangan.baseURL + this.state.biodata.bukti}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="buktitf-header footer">
                <div className="sideleft">
                  <h2>Informasi Daftar Ulang</h2>
                  <div className="dropdown-info">
                    <div
                      className="info-content transform transform-active"
                      id="info"
                    >
                      <p>
                        Untuk pembayaran online daftar ulang, anda dapat menuju
                        halaman konfirmasi pada tautan dibawah ini :
                      </p>
                      <a
                        href={
                          "/konfirmasi/pendaftaran/" +
                          this.props.match.params.id
                        }
                      >
                        Halaman Konfirmasi Pembayaran
                      </a>
                      <br />
                      <br />
                      <h4>Berkas Untuk Daftar Ulang :</h4>
                      <p>
                        <span>1.</span>
                        Fotocopy ijazah SMA/SMK/MA/Paket C sebanyak 2 lembar.
                        (bisa menyusul)
                      </p>
                      <p>
                        <span>2.</span>
                        Foto berwarna dengan ukuran 3x4 Sebanyak 4 lembar. (bisa
                        menyusul)
                      </p>
                      <p>
                        <span>3.</span>
                        Fotocopy Kartu Keluarga sebanyak 1 lembar. (bisa
                        menyusul)
                      </p>
                      <p>
                        <span>4.</span>
                        Jika anda sudah bekerja, Fotocopy Surat Keterangan Kerja
                        sebanyak 1 lembar. (optional & bisa menyusul)
                      </p>
                      <p>
                        <span>5.</span>
                        Jika transfer dari kampus lain atau naik jenjang,
                        Fotocopy Ijazah & Transkrip Nilai sebanyak 2 lembar.
                        (optional & bisa menyusul)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="kontak-asia">
                  <h2>Hubungi Kami</h2>
                  <p className="judul-kontak-asia">
                    Scan Barcode berikut atau klik tombol di bawah ini
                  </p>
                  <div className="konten-kontak-asia">
                    <img src="https://i.ibb.co/pLxQqS8/callwa.png" />
                    <div className="button">
                      <a
                        className="contact-button"
                        href="https://api.whatsapp.com/send?phone=6287881444088&text=Permisi%2C%20Saya%20ingin%20bertanya%20kak.%0A%0A"
                        target="_BLANK"
                      >
                        <i className="fab fa-whatsapp whatsapp" />
                        <h3>Whatsapp</h3>
                      </a>
                      <a className="contact-button" href="tel:0341-478877">
                        <i className="fas fa-phone phone" />
                        <h3>Call. 0341-478877</h3>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
