const formatPhone = (e) => {
    let phoneNumber = e.currentTarget.value;

    // Menghapus karakter selain angka
    phoneNumber = phoneNumber.replace(/\D/g, '');

    // Menghapus awalan "0" jika ada
    phoneNumber = phoneNumber.replace(/^0+/, '');

    // Menghapus dua angka pertama jika dimulai dengan "62"
    if (phoneNumber.startsWith('62') && phoneNumber.length > 2) {
        phoneNumber = phoneNumber.substring(2);
    }

    e.currentTarget.value = phoneNumber;
    return null;
};

export default formatPhone;