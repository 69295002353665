import React, { Component } from 'react';

class GoogleTranslateButton extends Component {
    componentDidMount() {
        const script = document.createElement('script');
        script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        script.async = true;
        document.body.appendChild(script);

        window.googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement({
                autoDisplay: false,
                includedLanguages: 'id,en,ms,th,ko,ja,zh-TW'
            }, 'google_translate_element');
        };
    }

    componentWillUnmount() {
        delete window.googleTranslateElementInit;
    }

    render() {
        return (
            <div id="google_translate_element"></div>
        );
    }
}

export default GoogleTranslateButton;